<template>
  <div>
    <!-- F Categories -->
    <div>
      <label for="search_type" class="font-weight-bolder"
        >Search by Typing :</label
      >
      <div class="d-flex align-content-center justify-content-between w-100">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" class="text-muted" />
          </b-input-group-prepend>
          <b-form-input
            :value="searchQuery"
            placeholder="Search Service"
            @input="updateRouteQuery"
          />
        </b-input-group>
      </div>
    </div>
  </div>
</template>
      
<script>
import store from "@/store";
import router from "@/router";
import { useRouter } from "@core/utils/utils";
import { ref, reactive, computed, watch } from "@vue/composition-api";
import {
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  props: {
    urlName: {
      type: String,
      default: "",
    },
    fetchDatas: {
      type: Function,
    },
  },
  setup(props, { emit }) {
    const { route, router } = useRouter();

    // Search Query
    const routeQuery = computed(() => route.value.query.search)
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    watch(searchQuery, () => props.fetchDatas())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.search = val
      else delete currentRouteQuery.search

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    return {
      updateRouteQuery,
      searchQuery
    };
  },
};
</script>