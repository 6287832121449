<template>
  <div>
    <div>
      <b-card>
        <div class="w-100 text-uppercase">
          <h4 class="mb-3" v-if="!isEdit">Add New Event</h4>
          <h5 class="mb-3" v-if="isEdit">Edit Event</h5>
        </div>
        <form @submit.prevent="handleSubmit">
          <b-form-group class="mb-4">
            <!-- Title & Sub_Title -->
            <div class="mb-3">
              <b-row>
                <b-col>
                  <div>
                    <label for="title" class="font-weight-bolder">Title :</label>
                    <template v-if="isNewsMedia">
                      <b-form-input v-model="newsMediaPostForm.title" id="title" placeholder="Enter News Media Title"
                        required>
                      </b-form-input>
                    </template>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="subTitle" class="font-weight-bolder">Sub Title :</label>
                    <template v-if="isNewsMedia">
                      <b-form-input v-model="newsMediaPostForm.sub_title" id="subTitle"
                        placeholder="Enter News Media Sub Title" required>
                      </b-form-input>
                    </template>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- Categories -->
            <template v-if="isNewsMedia">
              <div class="mb-3">
                <label for="categories" class="font-weight-bolder">Categories :</label>
                <b-form-select v-model="newsMediaPostForm.category_id" required>
                  <b-form-select-option disabled value="">Please Select a category</b-form-select-option>
                  <b-form-select-option v-for="c in categories" :key="c.id" :value="c.id">{{ c.title
                  }}</b-form-select-option>
                </b-form-select>
              </div>
              <!-- <div class="mb-3">
                <label for="user" class="font-weight-bolder"
                  >User :</label
                >
                <b-form-select v-model="servicePostForm.user_id" required>
                  <b-form-select-option disabled value=""
                    >Please Select a User</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="u in users"
                    :key="u.id"
                    :value="u.id"
                    >{{ u.name }}</b-form-select-option
                  >
                </b-form-select>
              </div> -->
            </template>

            <!-- <template v-if="isNews">
              <div class="mb-3">
                <label for="categories" class="font-weight-bolder"
                  >Categories :</label
                >
                <b-form-select v-model="newsPostForm.category_id" required>
                  <b-form-select-option disabled value=""
                    >Please Select a category</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="c in categories"
                    :key="c.id"
                    :value="c.id"
                    >{{ c.name }}</b-form-select-option
                  >
                </b-form-select>
              </div>
            </template> -->

            <!-- Description -->
            <template v-if="isNewsMedia">
              <div class="mb-3">
                <label for="des" class="font-weight-bolder">Description :</label>
                <vue-editor :editorToolbar="quillBar" v-model="newsMediaPostForm.description"></vue-editor>
              </div>
            </template>
            <b-row class="mb-3">
              <!-- Status -->
              <template v-if="isNewsMedia">
                <b-col md="6">
                  <div>
                    <label for="status" class="font-weight-bolder">Status :</label>
                    <b-form-checkbox v-model="checked" @change="toggle(checked)" name="check-button" switch>
                    </b-form-checkbox>
                  </div>
                </b-col>
              </template>
              <template v-if="isNewsMedia">
                <b-col>
                  <div>
                    <label for="e_start" class="font-weight-bolder">Posted Date :</label>
                    <b-form-datepicker id="e_start" v-model="newsMediaPostForm.posted_at" class="mb-2"
                      required></b-form-datepicker>
                  </div>
                </b-col>
              </template>
            </b-row>

            <!-- Photos -->
            <template v-if="!isEdit">
              <div>
                <label for="contact-info" class="font-weight-bolder">Photos :</label>
                <div>
                  <MyPhotoUpload @getPhotoId="uploadPhoto" />
                </div>
              </div>
            </template>
          </b-form-group>

          <template v-if="!isEdit">
            {{ allUploaded() }}
          </template>

          <!-- buttons -->  
          <div class="d-flex flex-column flex-md-row">
            <div class="w-100 text-center mb-2 mb-md-0">
              <b-button type="button" variant="outline-primary" @click="() => router.go(-1)" class="col-md-10 col-lg-8">
                <!-- <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner> -->
                <span>Back</span>
              </b-button>
            </div>

            <div class="w-100 text-center" v-if="!isEdit">
              <b-button variant="primary" class="col-md-10 col-lg-8" type="submit" :disabled="!isAllUploaded">
                <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
                <span v-else>Submit</span>
              </b-button>
            </div>

            <div class="w-100 text-center" v-if="isEdit">
              <b-button type="button" variant="primary" @click="updateData()" class="col-md-10 col-lg-8">
                <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
                <span v-else>Update</span>
              </b-button>
            </div>
          </div>


          <!-- <div class="w-100 text-center" v-if="!isEdit">
            <b-button variant="primary" class="col-6" type="submit">
              <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
              <span v-else>Submit</span>
            </b-button>
          </div> -->
        </form>

        <!-- <div class="w-100 text-center" v-if="isEdit">
          <b-button type="button" variant="primary" @click="updateData(servicePostForm.id)" class="col-6">
            <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
            <span v-else>Update</span>
          </b-button>
        </div> -->
      </b-card>
    </div>
  </div>
</template>
  
<script>
import store from "@/store";
// import MapLocationSelector from 'vue-latlng-picker';
import Table from "../components/Table.vue";
import { VueEditor } from "vue2-quill-editor";
import MyPhotoUpload from "../components/MyPhotoUpload.vue";
import FCategoriesCom from "../components/FCategoriesCom.vue";
import FStatesCom from "../components/FStatesCom.vue";
import FSearchInput from "../components/FSearchInput.vue";
// import router from "@/router";
import { useRouter } from "@core/utils/utils";
import {
  ref,
  reactive,
  computed,
  watch,
  // ref, watch, computed, onUnmounted,
} from "@vue/composition-api";
import {
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox,
  BFormTimepicker,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    BFormTimepicker,
    BFormDatepicker,
    // MapLocationSelector,
    VueEditor,
    Table,
    MyPhotoUpload,
    FCategoriesCom,
    FStatesCom,
    FSearchInput,
  },
  setup(props, { emit }) {
    const { route, router } = useRouter();
    const routerQuery = router.app._route.query;
    const fields = [
      { key: "id", label: "#" },
      { key: "title", label: "title" },
      { key: "sub_title", label: "sub title" },
      { key: "category.title", label: "category name" },
      { key: "actions", label: "actions" },
    ];
    const spinner = ref(false);
    const checked = ref(true);
    const publish = ref(true);
    const isAllUploaded = ref(false);
    const categories = ref(JSON.parse(routerQuery.categories));
    const isNewsMedia = ref(JSON.parse(routerQuery.isNewsMedia));
    const isEdit = ref(JSON.parse(routerQuery.isEdit));
    const mulImgs = ref([]);
    const newsMediaPostForm = reactive({
      id: "",
      title: "",
      category_id: "",
      sub_title: "",
      description: "",
      posted_at: "",
      status: "active",
      photos: [],
    });
    const quillBar = [['link', 'image' , 'video'],['bold', 'italic', 'underline'],[{ 'list': 'ordered'}, { 'list': 'bullet' }],['code-block'] , ['blockquote'],[{ 'header': 1 }, { 'header': 2 }],[{ 'script': 'sub'}, { 'script': 'super' }],[{ 'size': ['small', false, 'large', 'huge'] }],[{ 'header': [1, 2, 3, 4, 5, 6, false] }],[{ 'color': [] }, { 'background': [] }],[{ 'font': [] }],[{ 'align': [] }],];

    //Datas Fetching
    //Functions
    // const locationUpdated = (latlng) => {
    //   latitude.value = latlng.lat;
    //   longitude.value = latlng.lng;
    // };
    const toggle = (c = checked.value) => {
      if (isNewsMedia.value) {
        c ? (newsMediaPostForm.status = "active")
          : (newsMediaPostForm.status = "unpublish");
      }
    };
    const uploadPhoto = photoIds => {
      // mulImgs.value = [...mulImgs.value, photoIds];
      // let multiplePhotos = mulImgs.value;
      // if (isNewsMedia.value) {
      //   newsMediaPostForm.photos = JSON.stringify(multiplePhotos);
      // }
      newsMediaPostForm.photos = [...newsMediaPostForm.photos, photoIds]
    };
    // Clear Datas
    const clearDatas = () => {
      if (isNewsMedia.value) {
        newsMediaPostForm.title = "";
        // newsMediaPostForm.category_id = 0;
        newsMediaPostForm.sub_title = "";
        newsMediaPostForm.posted_at = "";
        newsMediaPostForm.description = "";
        newsMediaPostForm.status = 'active';
      }
    };

    //Data Posting
    const handleSubmit = () => {
      spinner.value = true;
      if (isNewsMedia.value) {
        store
          .dispatch("app-mypages/postDatas", {
            path: "/our_news",
            payload: newsMediaPostForm,
          })
          .then((response) => {
            spinner.value = false;
            isEdit.value = false;
            clearDatas();
            router.go(-1);
          });
      }
    };

    // Data Editing
    if (isEdit.value) {
      const editServiceData = () => {
        let editDatas = JSON.parse(routerQuery.editDatas);
        // console.log(routerQuery.editDatas);
        // console.log(editDatas);

        if (isNewsMedia.value) {
          newsMediaPostForm.id = editDatas.id;
          newsMediaPostForm.title = editDatas.title;
          // newsMediaPostForm.category_id = 0;
          newsMediaPostForm.posted_at = editDatas.posted_at;
          newsMediaPostForm.sub_title = editDatas.sub_title;
          newsMediaPostForm.description = editDatas.description;
          newsMediaPostForm.status = editDatas.status;
        }
      };
      editServiceData();
    }
    const updateData = () => {
      spinner.value = true;
      if (isNewsMedia.value) {
        store
          .dispatch("app-mypages/updateData", {
            path: "/our_news",
            payload: newsMediaPostForm,
          })
          .then((response) => {
            spinner.value = false;
            isEdit.value = false;
            clearDatas();
            router.go(-1);
          });
      }
    };

    // Data Deleting
    // const delServiceData = (id) => {
    //   spinner.value = true;
    //   if (isService.value) {
    //     store
    //       .dispatch("app-mypages/delData", { path: "services", id })
    //       .then((response) => {
    //         spinner.value = false;
    //       });
    //   }
    // };

    // Close Function
    const close = () => {
      isEdit.value = false;
      clearDatas();
    };

    // All Uploaded
    const allUploaded = () => {
      if (newsMediaPostForm.title &&
        newsMediaPostForm.sub_title &&
        newsMediaPostForm.description &&
        newsMediaPostForm.posted_at) {
        isAllUploaded.value = true;
      }
    }

    return {
      fields,
      spinner,
      isEdit,
      isNewsMedia,
      checked,
      publish,
      categories,
      handleSubmit,
      toggle,
      uploadPhoto,
      newsMediaPostForm,
      router,
      isAllUploaded,
      allUploaded,
      close,
      quillBar
    };
  },
};
</script>
  