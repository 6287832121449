<template>
  <div>
    <!-- F States -->
    <div class="mb-2">
      <label for="categories" class="font-weight-bolder"
        >Search by State :</label
      >
      <b-form-select v-model="filter_state_id" @change="fetchFilterStateDatas">
        <b-form-select-option disabled value=""
          >Search by State</b-form-select-option
        >
        <b-form-select-option
          v-for="s in states"
          :key="s.id"
          :value="s.id"
          >{{ s.name }}</b-form-select-option
        >
      </b-form-select>
    </div>
  </div>
</template>
      
    <script>
import store from "@/store";
import router from "@/router";
import { useRouter } from "@core/utils/utils";
import { ref, reactive, computed, watch } from "@vue/composition-api";
import {
  BButton,
  BSpinner,
  BRow,
  BCol,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption,
  },
  props: {
    urlName: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { route, router } = useRouter();
    const filter_state_id = ref("");
    const states = ref([]);

    //Datas Fetching
    const fetchFilterStateDatas = () => {
      router.push(
        `/my-pages/${props.urlName}?state_id=${filter_state_id.value}`
      );
    };

    const fetchStates = () => {
      store.dispatch("app-mypages/fetchDatas", "states").then((response) => {
        states.value = response.data.data;
        emit("getStates", states.value);
      });
    };
    fetchStates();

    return {
      filter_state_id,
      fetchFilterStateDatas,
      states,
    };
  },
};
</script>